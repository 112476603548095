import { FunctionComponent, useEffect, useRef, useState } from "react";

import {
  Button,
  Form,
  LysaFormRef,
  Typography,
  RequiredValidator,
  Checkbox,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { Docs } from "../components/docs/Docs";
import {
  PensionMoveRequest,
  dataLifePensionMove,
} from "../../../../../data/dataLifePensionMove";
import { Move, useTransfer } from "../TransferContext";
import { TranslatedText } from "../../../../../components/TranslatedText";

import { useHistory } from "react-router";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TRANSFER_PENSIONS_URL } from "../TransferPensionsStory";
import { AccountType } from "../../../../../data/dataAccounts";

import "./Confirm.scss";
import { MoveCard } from "../components/moveCard/MoveCard";
import {
  SustainabilityImportance,
  SustainabilityPreference,
} from "../../../../../data/dataInvestments";

interface Props {
  next: (caseId: string) => void;
}

export const Confirm: FunctionComponent<Props> = ({ next }) => {
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();
  const [transfer, setTransfer] = useTransfer();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const history = useHistory();

  /**
   * Safe guard in case we loose context. On refresh etc. Send user back to start of story.
   */
  useEffect(() => {
    if (typeof transfer?.moves[0]?.institute === "undefined") {
      history.replace(getNavLink(TRANSFER_PENSIONS_URL));
    }
  }, [history, transfer?.moves]);

  return (
    <article className="transfer-pension-confirm">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }

          const { caseId, moves } = transfer;

          // Filter out moves with undefined properties
          const filteredMoves = moves.filter(
            (move) =>
              typeof move.institute !== "undefined" &&
              typeof move.insuranceNumber !== "undefined" &&
              typeof move.type !== "undefined"
          );

          // Check we don't have any undefined values in required properties
          if (
            typeof transfer.withdrawalAge !== "undefined" &&
            typeof transfer.withdrawalMonths !== "undefined" &&
            typeof transfer.sustainability !== "undefined" &&
            typeof transfer.takenRiskDeviation !== "undefined" &&
            typeof transfer.repayment !== "undefined" &&
            moves.length === filteredMoves.length
          ) {
            // Construct the moves object now that we know that no values are undefined.
            const requestMoves: PensionMoveRequest[] = filteredMoves.map(
              (move) => {
                if (move.type === AccountType.LYSA_TJP) {
                  return {
                    ...move,
                    insuranceNumber: move.insuranceNumber!,
                    institute: move.institute!,
                    employerTin: move.employerTin!,
                    type: move.type!,
                    repayment: transfer.repayment,
                  };
                } else {
                  return {
                    ...move,
                    insuranceNumber: move.insuranceNumber!,
                    institute: move.institute!,
                    type: move.type!,
                    repayment: transfer.repayment,
                  };
                }
              }
            );

            const important =
              transfer.sustainability === SustainabilityImportance.IMPORTANT;

            const specific =
              transfer.sustainability === SustainabilityImportance.IMPORTANT &&
              transfer?.sustainabilityPreference ===
                SustainabilityPreference.SPECIFIC;

            let requestParams;

            if (important) {
              if (specific) {
                requestParams = {
                  caseId: caseId,
                  moves: [...requestMoves],
                  advice: {
                    withdrawalAge: transfer.withdrawalAge,
                    withdrawalDuration: transfer.withdrawalMonths / 12, // Convert to years.
                    sustainability: transfer.sustainability,
                    takenRiskDeviation: transfer.takenRiskDeviation,
                    sustainabilityPreference: transfer.sustainabilityPreference,
                    pai: transfer.pai,
                    sfdr: transfer.sfdr,
                    taxonomy: transfer.taxonomy,
                  },
                };
              } else {
                requestParams = {
                  caseId: caseId,
                  moves: [...requestMoves],
                  advice: {
                    withdrawalAge: transfer.withdrawalAge,
                    withdrawalDuration: transfer.withdrawalMonths / 12, // Convert to years.
                    sustainability: transfer.sustainability,
                    takenRiskDeviation: transfer.takenRiskDeviation,
                    sustainabilityPreference: transfer.sustainabilityPreference,
                  },
                };
              }
            } else {
              requestParams = {
                caseId: caseId,
                moves: [...requestMoves],
                advice: {
                  withdrawalAge: transfer.withdrawalAge,
                  withdrawalDuration: transfer.withdrawalMonths / 12, // Convert to years.
                  sustainability: transfer.sustainability,
                  takenRiskDeviation: transfer.takenRiskDeviation,
                },
              };
            }

            dataLifePensionMove
              .initMove(requestParams)
              .then((response) => {
                const nextMoves: Move[] = response.moves;
                setTransfer({ moves: nextMoves });
                next(transfer.caseId);
              })
              .catch((error) => {
                console.log(`Couldn't initiate move. Error: ${error}`);
                throw error;
              });
          }
        }}
      >
        <section>
          <Typography type="h2">
            <TranslatedText id={"sweden.transfer-pension.confirm.header"} />
          </Typography>
          <Typography type="body">
            <TranslatedText id={"sweden.transfer-pension.confirm.ingress"} />
          </Typography>
        </section>
        <section className="move-cards">
          <Typography type="h3" className="move-cards-header">
            <TranslatedText
              id={"sweden.transfer-pension.confirm.move-details.header"}
            />
          </Typography>
          {transfer.moves.map((move, idx) => {
            return (
              <MoveCard
                key={`${move.id}-${idx}`}
                insuranceCompany={move.institute}
                insuranceNumber={move.insuranceNumber}
                insuranceHolderName={move?.employer}
                insuranceHolderTin={move?.employerTin}
                currentWorth={move?.currentWorth}
                type={move?.type}
              />
            );
          })}
        </section>
        <section>
          <Typography type="h3" className="document-header">
            <TranslatedText
              id={"sweden.transfer-pension.confirm.document-header"}
            />
          </Typography>
          <Docs />
        </section>
        <section className="agree-on-terms">
          <Snackbar type={SNACKBAR_TYPES.INFO} icon>
            <div style={{ textAlign: "left" }}>
              <TranslatedText id="sweden.transfer-pension.confirm.snackbar" />
            </div>
          </Snackbar>
          <Checkbox
            alternative={{
              text: intl.formatMessage({
                id: "sweden.transfer-pension.confirm.agreement.label",
              }),
              value: true,
            }}
            validators={[
              new RequiredValidator(
                intl.formatMessage({
                  id: "sweden.transfer-pension.confirm.agreement.error",
                })
              ),
            ]}
            checked={acceptedTerms}
            onChange={() => {
              setAcceptedTerms(!acceptedTerms);
            }}
          />
        </section>
        <Button
          block
          variant="primary"
          type="submit"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.confirm.button",
          })}
        />
      </Form>
    </article>
  );
};
